import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_OVoKJro5pc from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import siteConfig_JRId4KOeUL from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_JSh5nGhzCz from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_eoILE7jqvj from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import katex_fgfYgikewq from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/plugins/katex.ts";
import api_GFfDXud5Cr from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/plugins/api.ts";
import posthog_client_o9IEgU9LwG from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/plugins/posthog.client.js";
import defaults_M8ptihKv0z from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_OVoKJro5pc,
  siteConfig_JRId4KOeUL,
  inferSeoMetaPlugin_JSh5nGhzCz,
  titles_eoILE7jqvj,
  katex_fgfYgikewq,
  api_GFfDXud5Cr,
  posthog_client_o9IEgU9LwG,
  defaults_M8ptihKv0z
]