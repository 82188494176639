
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as _91_46_46_46app_93NqUGF9z9kVMeta } from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/[...app].vue?macro=true";
import { default as indexJrmoer9zNuMeta } from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/accountant-directory/index.vue?macro=true";
import { default as _91code_9308R66ta8sCMeta } from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/audit/[code].vue?macro=true";
import { default as _91slug_930vbAC1gBBQMeta } from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/blog/[slug].vue?macro=true";
import { default as _91slug_93pyaej37jvRMeta } from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/blog/category/[slug].vue?macro=true";
import { default as indexot7aXkgOueMeta } from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/blog/index.vue?macro=true";
import { default as _91slug_939vSL6WmYBXMeta } from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/company/[slug].vue?macro=true";
import { default as _91slug_93w3z7uXcoGrMeta } from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/glossary/[slug].vue?macro=true";
import { default as indexfXVHe3L9qwMeta } from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/glossary/index.vue?macro=true";
import { default as _91slug_93DKMgfmQerBMeta } from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/tutorials/[slug].vue?macro=true";
import { default as indexkxd0h9YbikMeta } from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/tutorials/index.vue?macro=true";
import { default as component_45stub8FKkrtNyIgMeta } from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub8FKkrtNyIg } from "/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "app",
    path: "/:app(.*)*",
    component: () => import("/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/[...app].vue")
  },
  {
    name: "accountant-directory",
    path: "/accountant-directory",
    component: () => import("/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/accountant-directory/index.vue")
  },
  {
    name: "audit-code",
    path: "/audit/:code()",
    component: () => import("/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/audit/[code].vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/blog/[slug].vue")
  },
  {
    name: "blog-category-slug",
    path: "/blog/category/:slug()",
    component: () => import("/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/blog/category/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/blog/index.vue")
  },
  {
    name: "company-slug",
    path: "/company/:slug()",
    component: () => import("/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/company/[slug].vue")
  },
  {
    name: "glossary-slug",
    path: "/glossary/:slug()",
    component: () => import("/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/glossary/[slug].vue")
  },
  {
    name: "glossary",
    path: "/glossary",
    component: () => import("/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/glossary/index.vue")
  },
  {
    name: "tutorials-slug",
    path: "/tutorials/:slug()",
    component: () => import("/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/tutorials/[slug].vue")
  },
  {
    name: "tutorials",
    path: "/tutorials",
    component: () => import("/codebuild/output/src2988118750/src/cybooks-marketing-boilerplate/pages/tutorials/index.vue")
  },
  {
    name: component_45stub8FKkrtNyIgMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub8FKkrtNyIg
  }
]