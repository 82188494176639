<script setup>
import { Content, fetchOneEntry, isPreviewing } from '@builder.io/sdk-vue'
import { ref } from 'vue'
import { registeredComponents } from '@/init-builder'

const runtimeConfig = useRuntimeConfig()

const route = useRoute()

const apiKey = runtimeConfig.public.builderIOApiKey
const canShowContent = ref(false)
const model = 'page'

const { data: content } = await useAsyncData('builderData', () =>
  fetchOneEntry({
    model,
    apiKey,
    userAttributes: {
      urlPath: route.path,
    },
  }),
)

canShowContent.value = content.value ? true : isPreviewing(route.path)
</script>

<template>
  <div>
    <div v-if="content.data.blank" class="relative">
      <LandingpageHeader />
      <div v-if="canShowContent">
        <Content
          :api-key="apiKey"
          :model="model"
          :content="content"
          :custom-components="registeredComponents"
        />
      </div>
      <div v-else>Content not Found</div>
    </div>
    <div v-else>
      <div v-if="content.data.navbar === 'testheader'">
        <base-test-header />
      </div>
      <div v-else>
        <base-header :background-variant="content.data.navbar" />
      </div>

      <div v-if="canShowContent">
        <Content
          :api-key="apiKey"
          :model="model"
          :content="content"
          :custom-components="registeredComponents"
        />
      </div>
      <div v-else>Content not Found</div>

      <BaseFooter />
    </div>
  </div>
</template>
