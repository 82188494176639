<template>
  <nav
    class="flex flex-col justify-center px-6 py-3 text-sm font-medium leading-none bg-white border-b border-zinc-300 text-neutral-800 max-md:px-5"
    role="navigation"
    aria-label="Main navigation"
  >
    <div
      class="flex flex-wrap justify-between items-center w-full max-md:max-w-full"
    >
      <div
        class="flex flex-wrap flex-1 shrink gap-10 items-center self-stretch my-auto whitespace-nowrap basis-0 min-w-[240px] max-md:max-w-full"
      >
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/f26ce3f172e247d498b5c25e90861046/1172fbbd787419b14b2b34c8ca205fba0d106359233401d3ef718ea0a36c39ac?apiKey=f26ce3f172e247d498b5c25e90861046&"
          class="object-contain shrink-0 self-stretch my-auto aspect-[4.27] w-[111px]"
          alt="Company Logo"
        />
        <div
          class="flex gap-9 justify-center items-center self-stretch my-auto min-w-[240px]"
          role="menubar"
        >
          <a
            href="#product"
            class="self-stretch my-auto hover:text-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-400 rounded-sm"
            role="menuitem"
            tabindex="0"
          >
            Product
          </a>
          <a
            href="#tools"
            class="self-stretch my-auto hover:text-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-400 rounded-sm"
            role="menuitem"
            tabindex="0"
          >
            Tools
          </a>
          <a
            href="#resources"
            class="self-stretch my-auto hover:text-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-400 rounded-sm"
            role="menuitem"
            tabindex="0"
          >
            Resources
          </a>
          <a
            href="#pricing"
            class="self-stretch my-auto hover:text-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-400 rounded-sm"
            role="menuitem"
            tabindex="0"
          >
            Pricing
          </a>
        </div>
      </div>
      <div
        class="flex gap-5 items-center self-stretch my-auto min-w-[240px]"
        role="navigation"
        aria-label="User actions"
      >
        <a
          href="/signin"
          class="self-stretch my-auto hover:text-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-400 rounded-sm"
          tabindex="0"
        >
          Sign in
        </a>
        <a
          href="/demo"
          class="self-stretch my-auto hover:text-orange-400 focus:outline-none focus:ring-2 focus:ring-orange-400 rounded-sm"
          tabindex="0"
        >
          See a demo
        </a>
        <a
          href="/signup"
          class="flex overflow-hidden gap-1 justify-center items-center self-stretch px-3.5 py-3 my-auto font-semibold text-white bg-orange-600 rounded-lg hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2"
          tabindex="0"
        >
          <span class="self-stretch px-5 my-auto">Try for free</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavigationBar',
}
</script>
